























import {Component, Prop, Vue} from "vue-property-decorator";
import {Station} from "@/models/station";

@Component({})
export default class CustomerHeaderMachines extends Vue {

  @Prop({ default: 0 })
  public machines!: number;

}

